<template>
  <el-row type="flex" justify="center" align="middle" class="login-row">
    <el-col :xs="20" :sm="16" :md="12" :lg="6">
      <el-tabs type="border-card" :value="activeTab">
        <el-tab-pane :label="$t('login.login')" name="login">
          <el-form
            class="login-form"
            :rules="rules"
            :model="model"
            ref="form"
            :validate-on-rule-change="false"
            @keyup.enter.native="handleLogin()"
          >
            <el-form-item prop="email">
              <el-input
                v-model="model.email"
                :placeholder="$t('login.username_or_email')"
                prefix-icon="el-icon-user"
                @blur="trimEmpty()"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="model.password"
                :placeholder="$t('login.password')"
                type="password"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                  <el-link
                    :underline="false"
                    class="forget-password"
                    type="success"
                    @click="toForgetPassword"
                    >{{ $t("login.forget_password") }}</el-link
                  >
                </el-col>
                <el-col :sm="{ span: 15, offset: 1 }" align="right">
                  <el-button type="primary" block @click="handleLogin()">
                    {{ $t("login.login") }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('login.register')" name="register">
          <Register />
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */
import formMixin from "@/mixins/form";
import ToeflMixin from "@/mixins/toefl.js";
import Register from "@/views/Register";

export default {
  metaInfo() {
    return {
      title: "Login - " + this.CompanyName
    };
  },

  components: {
    Register
  },

  mixins: [formMixin, ToeflMixin],

  props: [],
  data: () => {
    return {
      model: {
        email: "",
        password: ""
      },
      lineLogin: "",
      activeTab: "login",
      location: ""
    };
  },
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: this.$t("register.please_input_username_or_email"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {},

  async mounted() {
    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    this.location = iso_code;
    if (this.$route.name === "Register") {
      this.activeTab = "register";
    }
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
  },

  methods: {
    ...mapActions("user", ["login"]),

    register() {
      this.$router.push({ name: "Register" });
    },
    trimEmpty() {
      this.model.email = this.model.email.trim();
    },
    async handleLogin() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      await this.login({
        ...this.model,
        privacy_info: [this.location, this.userAgent(), this.browser()]
      });

      await this.$message({
        message: this.$t("message.login_success"),
        type: "success"
      });

      let nextRoutePath = localStorage.getItem("lastAccessRoutePath");
      localStorage.setItem("lastAccessRoutePath", "");

      if (!nextRoutePath) {
        this.$router.push({ name: "LandingPage" });
      }

      this.$router.push({ path: nextRoutePath });
    },
    toForgetPassword() {
      let email = undefined;
      if (this.model.email !== "") {
        email = this.model.email;
      }
      this.$router.push({
        name: "ForgetPassword",
        query: {
          email: email
        }
      });
    },
    async fetchLineLoginAuthURL() {
      let url = await this.$router.resolve({
        name: "LineLogin"
      });

      let lineLoginAuthUrl = await lineLoginApis.getAuthURL({
        redirect_uri: window.location.origin + url.href
      });

      this.lineLogin = lineLoginAuthUrl.url;
    },
    sw({ label }) {
      this.$router.push({ name: label });
    }
  }
};
</script>

<style scoped>
.login-row {
  position: relative;
  top: 100px;
}

.el-card /deep/ .el-card__header {
  background: var(--themeColor);
}

.clearfix {
  color: white;
}

.forget-password {
  font-size: 13px;
}

li a {
  text-decoration: none;
}

.el-button {
  outline: none;
}
</style>
